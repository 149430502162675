<div class="container-afiliados-full">
   <div class="container-afiliados">
       <div class="header-menu">
         <div class="menu">
            <div (click)="showResumen()">
               <!-- <img class="icon-menu" src="assets/iconos/papel.png"> -->
               <!-- <label style="margin-bottom: 0px;">Resumen</label> -->
               <button id="btnmenuresumen" class="menu-item" [class.menu-item-active]="showSectionResumen">Resumen</button>
            </div>
            <div (click)="showAfiliados()">
              <!-- <img class="icon-menu" src="assets/iconos/contactosbook.png"> -->
              <!-- <label style="margin-bottom: 0px;" class="txt-item-active" >Afiliados</label> -->
              <button id="btnmenuafiliados" class="menu-item menu-item-active" [class.menu-item-active]="showSectionAfiliados">Afiliados</button>
           </div>
         </div>
       </div>
       <div class="container-sections">
          <div class="section-left">
               <div class="container-logo">
                  <img class="logo-company" src="{{dataAfiliadp.img}}">
               </div>
               <div class="container-company-name">
                   <label class="title-company">{{dataAfiliadp.nombre}}</label>
                   <label class="subtitle-company">{{dataAfiliadp.tipoAfiliado}}</label>
               </div>
               <div class="container-data-finance">
                  <div class="container-monedero">
                    <label class="txt-finance-title">MONEDERO</label>
                    <label class="txt-finance-info">{{monedero}}</label>
                  </div>
                  <div class="container-porcentaje-mes">
                    <label class="txt-finance-title">PORCENTAJE DEL MES</label>
                    <label class="txt-finance-info">{{porcentaje}}</label>
                  </div>
               </div>
               <div class="container-company-info">
                    <div class="item-company-info">
                         <div style="padding: 0px 16px 0px 10px;">
                            <img class="icon-item" style="width: 14px; padding-left: 2px;" src="assets/iconos/celitem.png" alt="">
                         </div>
                         <div class="item-info">
                            <label class="item-txt">Phone</label>
                            <label class="item-phone">{{dataAfiliadp.Telefono}}</label>
                         </div>
                    </div>
                    <div class="item-company-info" style="padding-top: 10px;">
                      <div style="padding: 0px 16px 0px 10px;">
                         <img class="icon-item" src="assets/iconos/correoitem.png" alt="">
                      </div>
                      <div class="item-info">
                         <label class="item-txt">Email</label>
                         <label class="item-phone">{{dataAfiliadp.Correo}}</label>
                      </div>
                    </div>
                    <div class="item-company-info" style="padding-top: 10px; border-bottom: none">
                      <div style="padding: 0px 16px 0px 10px;">
                         <img class="icon-item" src="assets/iconos/ubicitem.png" alt="">
                      </div>
                      <div class="item-info">
                         <label class="item-txt">Location</label>
                         <label class="item-phone">{{dataAfiliadp.direccion}}</label>
                      </div>
                    </div>
               </div>
          </div>

          <div class="section-center">
            <div class="menu">
              <div (click)="showResumen()">
                 <!-- <img class="icon-menu" src="assets/iconos/papel.png"> -->
                 <!-- <label style="margin-bottom: 0px;">Resumen</label> -->
                 <button id="btnmenuresumenmovilresp" class="menu-item" [class.menu-item-active]="showSectionResumen">Resumen</button>
              </div>
              <div (click)="showAfiliados()">
                <!-- <img class="icon-menu" src="assets/iconos/contactosbook.png"> -->
                <!-- <label style="margin-bottom: 0px;" class="txt-item-active" >Afiliados</label> -->
                <button id="btnmenuafiliadosresp" class="menu-item menu-item-active" [class.menu-item-active]="showSectionAfiliados">Afiliados</button>
             </div>
           </div>
          </div>

          <div class="section-right">
              <div class="section-afiliados" *ngIf="showSectionAfiliados">
                  <div class="title-container">
                      <label class="title">Afiliados</label>
                      <hr style="width: 100%; background-color: #f05b23; opacity: 1;">
                  </div>

                  <div class="sect-afiliados-full">
                    <div class="sect-afiliados-left">
                      <div class="container-input-tipo">
                       <div class="select">
                          <select class="select-class" [(ngModel)]="afiliadoidtipocod" (change)="onTipoCodigoSelect()">
                            <option value="-1">Tipo de código a generar</option>
                            <option value="{{item.idtpocod}}" *ngFor="let item of lsttipocodigo">{{item.tipo}}</option>
                          </select>
                       </div>
                       <button class="btn-primary" style="margin-left: 15px;" (click)="GenerarNuevoCodigo()">Generar</button>
                      </div>

                      <div id="searchmovil" class="container-3">
                        <span class="icon"><i class="fas fa-search"></i></span>
                        <input [(ngModel)]="searchCode"  type="search" id="search" placeholder="Buscar código..." (keyup.enter)="searchCodeMethod()" (ngModelChange)="onSearchChange()"/>
                      </div>

                      <div class="table-full-codes">
                        <div class="container-title-codes-items">
                          <div id="searchdesktop" class="container-2">
                            <span class="icon"><i class="fas fa-search"></i></span>
                            <input [(ngModel)]="searchCode"  type="search" id="search" placeholder="Buscar..." (keyup.enter)="searchCodeMethod()" (ngModelChange)="onSearchChange()"/>
                          </div>
                          <!-- <div class="items-title" *ngIf="showCodeTxt"><label class="txt-code-dataitem"><i class="fas fa-search icon-seach" (click)="showSectionSearch()"></i>Código</label></div> -->
                          <div id="txtheadercodigo" class="items-title" style="display: none;"><label class="txt-code-dataitem">Código</label></div>
                          <div class="items-title"><label class="txt-code-dataitem">Tipo</label></div>
                          <div class="items-title"><label class="txt-code-dataitem">Estatus</label></div>
                          <div class="items-title"><label class="txt-code-dataitem">Acciones</label></div>
                        </div>
                        <div class="table-content-codes">
                           <div class="table-row-codes" id="{{item.idcod}}" *ngFor="let item of dataAfiliadp.lstCodigoas" [class.selected]="filaSeleccionada === item" (click)="seleccionarFila(item)">
                              <div class="column-1"><label class="txt-data-codes" style="font-size:13px;">{{ item.codigo }}</label></div>
                              <div class="column-1"><label class="txt-data-codes">{{ item.tipo }}</label></div>

                              <ng-container *ngIf="item.Estatus === 'Bloqueado'; else activeStatus">
                                <div class="column-1"><label class="code-block"><img src="../../../assets/iconos/precaucionicon.png" class="icon-code-status" style="width: 15px;">{{ item.Estatus }}</label></div>
                              </ng-container>

                              <ng-template #activeStatus>
                                <div class="column-1"><label class="code-active"><img src="../../../assets/iconos/verificacionicon.png" class="icon-code-status">Activo</label></div>
                              </ng-template>

                              <ng-container *ngIf="item.Estatus === 'Bloqueado'; else actionActiveStatus">
                                <div class="column-1"><label class="txt-data-codes"></label></div>
                              </ng-container>

                              <ng-template #actionActiveStatus>
                                <div class="column-1"><label class="btn-block-code" (click)="bloquearcodigo(item.idcod)">Bloquear</label></div>
                              </ng-template>

                              <!-- <div class="column-1"><label class="txt-data-codes"></label></div> -->
                           </div>
                           <!-- <div class="table-row-codes">
                            <div class="column-1"><label class="txt-data-codes">E7A48A15E8</label></div>
                            <div class="column-1"><label class="txt-data-codes">General</label></div>
                            <div class="column-1"><label class="code-active"><img src="../../../assets/iconos/verificacionicon.png" class="icon-code-status">Activo</label></div>
                            <div class="column-1"><label class="btn-block-code">Bloquear</label></div>
                           </div>
                           <div class="table-row-codes">
                            <div class="column-1"><label class="txt-data-codes">E7A48A15E8</label></div>
                            <div class="column-1"><label class="txt-data-codes">General</label></div>
                            <div class="column-1"><label class="code-active"><img src="../../../assets/iconos/verificacionicon.png" class="icon-code-status">Activo</label></div>
                            <div class="column-1"><label class="btn-block-code">Bloquear</label></div>
                           </div>
                           <div class="table-row-codes">
                            <div class="column-1"><label class="txt-data-codes">E7A48A15E8</label></div>
                            <div class="column-1"><label class="txt-data-codes">General</label></div>
                            <div class="column-1"><label class="code-active"><img src="../../../assets/iconos/verificacionicon.png" class="icon-code-status">Activo</label></div>
                            <div class="column-1"><label class="btn-block-code">Bloquear</label></div>
                           </div>
                           <div class="table-row-codes">
                            <div class="column-1"><label class="txt-data-codes">E7A48A15E8</label></div>
                            <div class="column-1"><label class="txt-data-codes">General</label></div>
                            <div class="column-1"><label class="code-active"><img src="../../../assets/iconos/verificacionicon.png" class="icon-code-status">Activo</label></div>
                            <div class="column-1"><label class="btn-block-code">Bloquear</label></div>
                           </div> -->

                           <!-- <div class="table-column-movil" *ngFor="let item of dataRelacionAfiliado">
                             <div class="table-row-movil">
                               <div class="head-item-movil">Nombre</div>
                               <div class="data-item-movil">{{item.nombre}}</div>
                             </div>
                             <div class="table-row-movil">
                               <div class="head-item-movil">Telefono</div>
                               <div class="data-item-movil">{{item.telefono}}</div>
                             </div>
                             <div class="table-row-movil">
                               <div class="head-item-movil">Correo</div>
                               <div class="data-item-movil">{{item.correo}}</div>
                             </div>
                           </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="sect-afiliados-center"></div>
                    <div class="sect-afiliados-right">
                       <input class="class_input" type="text" placeholder="Nombre completo" [(ngModel)]="relnombre" >
                       <input class="class_input" type="text" placeholder="Teléfono" [(ngModel)]="relTelefn" (input)="soloNumeros($event)" maxlength="13">
                       <input class="class_input" type="text" placeholder="Correo electrónico" [(ngModel)]="relcorreo" (input)="validarCorreoElectronico()">
                       <button class="btn-add-afiliado" style=" padding: 0px 12px" (click)="Agregarrelacion()">Agregar afiliado</button>
                    </div>
                  </div>

                  <div class="container-inputsdata">
                     <!-- <div class="select">
                       <select class="select-class" [(ngModel)]="afiliadoidtipocod">
                         <option value="-1">Tipo de código a generar</option>
                         <option value="{{item.idtpocod}}" *ngFor="let item of lsttipocodigo">{{item.tipo}}</option>
                       </select>
                     </div>
                     <button class="btn-primary" style="margin-right: 15px;" (click)="GenerarNuevoCodigo()">Generar</button> -->
                     <!-- <div class="select">
                       <select class="select-class" (change)="onCodigoSeleccionado($event)" [(ngModel)]="afiliadoidcod">
                         <option value="-1">Códigos disponibles</option>
                         <option value="{{item.idcod}}" *ngFor="let item of dataAfiliadp.lstCodigoas">{{item.codigo }} {{item.tipo  }}  {{item.Estatus  }} </option>
                       </select>
                     </div>
                    <button class="btn-primary" (click)="bloquearcodigo()">Bloquear</button> -->
                  </div>
                  <div class="container-inputsdata">
                      <!-- <input class="class_input" type="text" placeholder="Nombre completo" [(ngModel)]="relnombre" >
                      <input class="class_input" type="text" placeholder="Teléfono" [(ngModel)]="relTelefn" (input)="soloNumeros($event)" maxlength="13">
                      <input class="class_input" type="text" placeholder="Correo electrónico" [(ngModel)]="relcorreo" (input)="validarCorreoElectronico()">
                      <button class="btn-primary" style=" padding: 0px 12px" (click)="Agregarrelacion()">Agregar</button> -->
                  </div>
                  <div class="container-tabledata">
                      <div class="container-table">
                         <div class="title-table">
                           <label style="color: #fff; font-size: 12px; margin-bottom: 0px;">Afiliados registrados al código</label>
                         </div>
                         <div class="title-dataitems">
                           <div class="column-1"><label class="txt-dataitem">Nombre</label></div>
                           <div class="column-1"><label class="txt-dataitem">Telefono</label></div>
                           <div class="column-2"><label class="txt-dataitem">Correo</label></div>
                         </div>
                         <div class="table-content">
                            <div class="table-row" *ngFor="let item of dataRelacionAfiliado">
                               <div class="column-1"><label class="txt-data">{{item.nombre}}</label></div>
                               <div class="column-1"><label class="txt-data">{{item.telefono}}</label></div>
                               <div class="column-1"><label class="txt-data">{{item.correo}}</label></div>
                               <div class="icon-delete"><img style="width: 20px;" [src]="currentHoveredItem === item ? imagenActual  : imagenOriginal"  (mouseover)="currentHoveredItem = item; cambiarImagen()" (mouseout)="currentHoveredItem = null; restaurarImagen()" (click)="bajarelacion(item.idrel)"></div>
                            </div>
                            <div class="table-column-movil" *ngFor="let item of dataRelacionAfiliado">
                              <div class="table-row-movil">
                                <div class="head-item-movil">Nombre</div>
                                <div class="data-item-movil">{{item.nombre}}</div>
                              </div>
                              <div class="table-row-movil">
                                <div class="head-item-movil">Telefono</div>
                                <div class="data-item-movil">{{item.telefono}}</div>
                              </div>
                              <div class="table-row-movil">
                                <div class="head-item-movil">Correo</div>
                                <div class="data-item-movil">{{item.correo}}</div>
                              </div>
                            </div>
                         </div>
                      </div>
                   </div>
              </div>
              <div class="section-resumen" *ngIf="showSectionResumen">
                <div class="title-container">
                  <label class="title">Resumen</label>
                  <hr style="width: 100%; background-color: #f05b23; opacity: 1;">
              </div>
              <div class="container-inputsdata">
                <!-- <div class="select">
                  <select class="select-class">
                    <option value="" disabled selected>Códigos disponibles</option>
                    <option value="opcion1">489FGK97</option>
                    <option value="opcion2">874H7UD8</option>
                    <option value="opcion3">590W3E4R</option>
                  </select>
                </div> -->
                <div class="input-date-class">
                  <input type="text" placeholder="Fecha inicio" class="input-date" onfocus="this.type='date'" [(ngModel)]="fechainiciohistorial">
                  <!-- <span class="fas fa-calendar-alt"></span> -->
                </div>
                <div class="input-date-class">
                  <input type="text" placeholder="Fecha fin" class="input-date" onfocus="this.type='date'" [(ngModel)]="fechafinhistorial">
                  <!-- <span class="fas fa-calendar-alt"></span> -->
                </div>
                 <button class="btn-primary" (click)="showDataResumen()">Generar</button>
              </div>

              <div class="container-tabledata">
                  <div class="container-table-resumen">
                     <div class="title-table">
                       <label style="color: #fff; font-size: 12px; margin-bottom: 0px;">Resumen de uso código seleccionado</label>
                     </div>
                     <div class="title-dataitems">
                       <div class="column-1"><label class="txt-dataitem">Rastreo envío</label></div>
                       <div class="column-1"><label class="txt-dataitem">Código</label></div>
                       <div class="column-1"><label class="txt-dataitem">Fecha</label></div>
                       <div class="column-1"><label class="txt-dataitem">Id tarjeta</label></div>
                       <div class="column-1"><label class="txt-dataitem">Importe envío</label></div>
                       <div class="column-1"><label class="txt-dataitem">Importe por</label></div>
                       <div class="column-1"><label class="txt-dataitem">Porcentaje</label></div>
                       <div class="column-1"><label class="txt-dataitem">Tipo código</label></div>
                       <div class="column-4"><label class="txt-dataitem">Tipo movimiento</label></div>
                     </div>
                     <div class="table-content-resumen">
                        <div class="table-row" *ngFor="let data of datahistoryresumen">
                           <div class="column-1"><label class="txt-data">{{ data.Rastreoenvio }}</label></div>
                           <div class="column-1"><label class="txt-data">{{ data.codigo }}</label></div>
                           <div class="column-1"><label class="txt-data">{{ data.fechamov }}</label></div>
                           <div class="column-1"><label class="txt-data">{{ data.idtarjeta }}</label></div>
                           <div class="column-1"><label class="txt-data">{{ data.impEnvio }}</label></div>
                           <div class="column-1"><label class="txt-data">{{ data.imppor }}</label></div>
                           <div class="column-1"><label class="txt-data">{{ data.por }}</label></div>
                           <div class="column-1"><label class="txt-data">{{ data.tipoCodigo }}</label></div>
                           <div class="column-1"><label class="txt-data">{{ data.tpomov }}</label></div>
                           <!-- <div class="column-1"><label class="txt-data">Obregón</label></div>
                           <div class="column-1"><label class="txt-data">Hermosillo</label></div>
                           <div class="column-1"><label class="txt-data">Humberto alonso garcia quiroz</label></div>
                           <div class="column-1"><label class="txt-data">6441675927</label></div> -->
                        </div>
                        <div class="table-column-movil" *ngFor="let data of datahistoryresumen">
                            <div class="table-row-movil">
                               <div class="head-item-movil">Rastreo envío</div>
                               <div class="data-item-movil">{{ data.Rastreoenvio }}</div>
                            </div>
                            <div class="table-row-movil">
                               <div class="head-item-movil">Código</div>
                               <div class="data-item-movil">{{ data.codigo }}</div>
                            </div>
                            <div class="table-row-movil">
                               <div class="head-item-movil">Fecha</div>
                               <div class="data-item-movil">{{ data.fechamov }}</div>
                            </div>
                            <div class="table-row-movil">
                               <div class="head-item-movil">Id tarjeta</div>
                               <div class="data-item-movil">{{ data.idtarjeta }}</div>
                            </div>
                            <div class="table-row-movil">
                               <div class="head-item-movil">Importe envío</div>
                               <div class="data-item-movil">{{ data.impEnvio }}</div>
                            </div>
                            <div class="table-row-movil">
                               <div class="head-item-movil">Importe por</div>
                               <div class="data-item-movil">{{ data.imppor }}</div>
                            </div>
                            <div class="table-row-movil">
                               <div class="head-item-movil">Porcentaje</div>
                               <div class="data-item-movil">{{ data.por }}</div>
                            </div>
                            <div class="table-row-movil">
                              <div class="head-item-movil">Tipo código</div>
                              <div class="data-item-movil">{{ data.tipoCodigo }}</div>
                           </div>
                           <div class="table-row-movil">
                            <div class="head-item-movil">Tipo movimiento</div>
                            <div class="data-item-movil">{{ data.tpomov }}</div>
                         </div>
                        </div>

                     </div>
                  </div>
               </div>
              </div>
         </div>
       </div>
   </div>
</div>
