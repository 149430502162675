import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentosService {

  constructor(private http: HttpClient) { }

  ngOnInit() {
    // const plat = this.rutaActiva.snapshot.params.plataforma;
    // this.plataforma = (plat && typeof plat != 'undefined') ? plat : '';

    // const url = window.location.href.toLowerCase();

    // this.rutaActiva.params.subscribe(params => {
    //   this.viajacode = params.code;
    //   console.log(this.viajacode )
    //   if ( this.viajacode !=  undefined){
    //     this.cargarviaja();
    //   }else {
    //     this.router.navigate(['/']);
    //   }

    // });
  }

  cargarDocumentos(idrsp: string){
    let url = `${environment.URL_APIPAQbeta}/cargarestructuraformato_tfp?idrsp=${idrsp}`
    return this.http.get<any>(url).toPromise();
  }

  guardarProgresoFormatoDigital(body: any){
    let url = `${environment.URL_APIPAQbeta}/OprfinalizarCuestionario_tfp`
    return this.http.post<any>(url,body);
  }
  finalizarCuestionarioFormatoDigital(body: any){
    let url = `${environment.URL_APIPAQbeta}/Oprcuestionario_tfp`
    return this.http.post<any>(url,body);
  }

  guardarDocsAdjuntos(body: any) {
    let url = `${environment.URL_APIPAQbeta}/OprguardarDocAdjCuestionario_tfp`
    return this.http.post<any>(url,body);
  }

  oprFilesFormatosDigitales(opr: string,
    idrsppre: string,
    idrsp: string,
    idpregunta: string,
    nombreFile: string,
    codigoFile: string,
    extension: string,
    idfile: string){
      let body = {
          opr : opr,
          idrsppre : idrsppre,
          idrsp : idrsp,
          idpregunta : idpregunta,
          nombreFile : nombreFile,
          codigoFile : codigoFile,
          extension : extension,
          idFile: idfile
      }
   let url = `${environment.URL_APIPAQbeta}/oprFilesFormatosDigitales`
   return this.http.post<any>(url, body);
  }

  cargaFilesFormatosDigitales(idrsppre: string){
    let url = `${environment.URL_APIPAQbeta}/cargaFilesFormatosDigitales?idrsppre=${idrsppre}`
    return this.http.get<any>(url).toPromise();
  }

  descargaFilesFormatosDigitales(idFile: string){
    let url = `${environment.URL_APIPAQbeta}/descargaFilesFormatosDigitales?idFile=${idFile}`
    return this.http.get<any>(url).toPromise();
  }

  CargarDatosAfiliado(idcliweb: string){
    let url = `${environment.URL_APIPAQbeta}/CargarDatosAfiliado?idcliweb=${idcliweb}`
    return this.http.get<any>(url).toPromise();
  }

  CargarAfiliadotiposCodigo(idcliweb: string){
    let url = `${environment.URL_APIPAQbeta}/CargarAfiliadotiposCodigo?idcliweb=${idcliweb}`
    return this.http.get<any>(url).toPromise();
  }

  CargarAfiliadosRelacion(idcliweb: string, idcod: string ){
    let url = `${environment.URL_APIPAQbeta}/CargarAfiliadosRelacion?idcliweb=${idcliweb}&idcod=${idcod}`
    return this.http.get<any>(url).toPromise();
  }

  oprAfiliadoCodigos(opr: String, idcliweb: String, idcod: String, idtpocod : String, codigo: String, idedocod: string){
    let url = `${environment.URL_APIPAQbeta}/oprAfiliadoCodigos?opr=${opr}&idcliweb=${idcliweb}&idcod=${idcod}&idtpocod=${idtpocod}&codigo=${codigo}&idedocod=${idedocod}`
    return this.http.get<any>(url).toPromise();
  }

  oprAfiliadosRelacion(opr: String,idrel : String, idcliweb: String, idcod: String, nombre : String, telefono: String, correo: string){
    let url = `${environment.URL_APIPAQbeta}/oprAfiliadosRelacion?opr=${opr}&idrel=${idrel}&idcliweb=${idcliweb}&idcod=${idcod}&nombre=${nombre}&telefono=${telefono}&correo=${correo}`
    return this.http.get<any>(url).toPromise();
  }

 // /CargarHistorialAfilado?idcliweb=1&fchini=20231001&fchfin=20231031
  cargarHistorialAfilado( idcliweb:String, fchini:String, fchfin:String){
    let url = `${environment.URL_APIPAQbeta}/CargarHistorialAfilado?idcliweb=${idcliweb}&fchini=${fchini}&fchfin=${fchfin}`
    return this.http.get<any>(url).toPromise();
  }

  validarCodigoAcceso(body: any): Observable<any[]> {
    let url = `${environment.URL_APIPAQbeta}/codigosVerFormatos`
    return this.http.post<any>(url, body)
   }

   buscarCodigoAfiliado(idcliweb: string, idtpo: string, valor: string, ){
    let url = `${environment.URL_APIPAQbeta}/busquedaAfiliado?idcliweb=${idcliweb}&idtpo=${idtpo}&valor=${valor}`
    return this.http.get<any>(url).toPromise();
  }





// cargarFormatodocumento(){

//     this.documentservices.spcargaEstructuradocumento(this.viajacode ).subscribe((datos) => {
//       this.rspservice = datos;


//     });
//   }
}
